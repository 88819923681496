import styled from 'styled-components';
import { Flex, Text } from '@strikelabs/luna';
import { rem } from 'polished';

import { Container } from 'components/common';

export const Outer = styled(Container)`
  margin: ${({ theme }) => `${theme.spacing.xl} auto 0`};

  ${({ theme }) => theme.media.desktop} {
    margin: ${({ theme }) => `${theme.spacing.xxl} auto 0`};
  }
`;

export const IntroLarge = styled(Text).attrs({ as: 'h3', ext: true })`
  margin-bottom: ${({ theme }) => theme.spacing.xl};
  max-width: 75%;
`;

export const ColumnWrapper = styled(Flex)`
  flex-direction: column;

  ${({ theme }) => theme.media.smallTablet} {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  ${({ theme }) => theme.media.giant} {
    flex-wrap: nowrap;
  }
`;

export const Value = styled.div`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing.md};

  ${({ theme }) => theme.media.smallTablet} {
    width: 45%;
  }

  ${({ theme }) => theme.media.giant} {
    width: 22%;
    margin-bottom: 0;
  }
`;

export const ValueTitle = styled(Text).attrs({ as: 'h3', ext: true })`
  margin-bottom: ${({ theme }) => theme.spacing.rg};
  color: ${({ theme }) => theme.colors.darkMenuHighlight};

  ${({ theme }) => theme.media.desktop} {
    font-size: ${rem(27)};
    margin-bottom: ${({ theme }) => theme.spacing.md};
  }
`;

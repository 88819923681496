import React from 'react';
import { Text, Flex, ButtonAnimated, Box } from '@strikelabs/luna';
import { Container } from 'components/common';

import { BoxTitle } from './style';

const WhatElse = () => {
  return (
    <Container>
      <Text as="h2" ext mb={[4, 4, 4, 4, 5]}>
        What else can we do for you?
      </Text>
      <Flex flexWrap="wrap" justifyContent="space-between">
        <Flex
          flexDirection="column"
          p={[2, 2, 2, 2, 3]}
          mb={[3, 3, 3, 3, 3, null]}
          width={[1, 1, 1, 1, 4.9 / 10, 2.9 / 9]}
          backgroundColor="#201F1F"
        >
          <BoxTitle as="h3" ext color="white" mb={4}>
            Mortgage advice
          </BoxTitle>
          <Text styleType="body" color="white" ext mb={4}>
            We&apos;ve got the fast, stress-free mortgage advice you&apos;ve
            been looking for.
          </Text>
          <Box mt={'auto'}>
            <ButtonAnimated
              type="button"
              to="/services/mortgages"
              color="background"
            >
              Learn more
            </ButtonAnimated>
          </Box>
        </Flex>
        <Flex
          flexDirection="column"
          p={[2, 2, 2, 2, 4]}
          mb={[3, 3, 3, 3, 3, null]}
          width={[1, 1, 1, 1, 4.9 / 10, 2.9 / 9]}
          backgroundColor="#201F1F"
        >
          <BoxTitle as="h3" ext color="white" mb={4}>
            Conveyancing
          </BoxTitle>
          <Text styleType="body" color="white" ext mb={4}>
            Make the legal side of moving a breeze. We charge a flat fee that
            you only pay when your house sale is completed.
          </Text>
          <Box mt={'auto'}>
            <ButtonAnimated
              type="button"
              to="/services/conveyancing"
              color="background"
            >
              Learn more
            </ButtonAnimated>
          </Box>
        </Flex>
        <Flex
          flexDirection="column"
          p={[2, 2, 2, 2, 4]}
          mb={[3, 3, 3, 3, 3, null]}
          width={[1, 1, 1, 1, 4.9 / 10, 2.9 / 9]}
          backgroundColor="#201F1F"
        >
          <BoxTitle as="h3" ext color="white" mb={4}>
            Moving services
          </BoxTitle>
          <Text styleType="body" color="white" ext mb={4}>
            We’ll take care of all of the little details removal vans to
            forwarding your mail &mdash; we&apos;ll even help you switch
            broadband.
          </Text>
          <Box mt={'auto'}>
            <ButtonAnimated
              type="button"
              to="/services/moving-services"
              color="background"
            >
              Learn more
            </ButtonAnimated>
          </Box>
        </Flex>
      </Flex>
    </Container>
  );
};

export default WhatElse;

import React from 'react';
import LazyLoad from 'react-lazyload';

import Layout from 'components/Layout';
import SEO from 'components/SEO';
import Hero from 'components/Hero';
import Stats from 'components/Stats';
import ImageTextBlock from 'components/ImageTextBlock';
import { OurValues, WhatElse } from 'components/Pages/WhyStrike/Sections';
import BrandImg from 'images/strike-brand.png';
import WhyWorkWithUs from 'images/why-work-with-us.png';
import HaveQuestions from 'images/have-questions.png';
import WhatWeveBeenUpTo from 'images/what-weve-been-upto.png';

const WhyStrike = () => {
  return (
    <Layout showFixedCta>
      <SEO
        title="About Us | Strike | A Better Way to Buy and Sell Your Home"
        description="We'd Had Enough Of High Fees and Pointless Jargon So We Created a Simple, Transparent and Free Way To Buy And Sell Your Home. Go On Strike. Strike A Better Deal."
        canonical="/why-strike"
        metaImage={BrandImg}
      />
      <Hero
        title="We’re making moving simple, transparent, and free"
        subtitle="We've had enough of the high fees and the pointless jargon, so we've created a better way to buy and sell homes - and we want you to join us."
        image="hero-why-strike.png"
        imageAlt="Dog and owner"
      />
      <OurValues />
      <LazyLoad height={438} offset={80}>
        <Stats />
      </LazyLoad>
      <LazyLoad height={482} offset={80}>
        <WhatElse />
      </LazyLoad>
      <LazyLoad height={460} offset={80}>
        <ImageTextBlock
          title="Work with us"
          cta="See current vacancies"
          link="/work-at-strike"
          image={WhyWorkWithUs}
          altTag="Work with us"
        >
          We&apos;re changing what it means to be an estate agent &mdash; and
          we&apos;re always on the lookout for people with ambitions as big as
          ours. Learn more about joining the Strike team.
        </ImageTextBlock>
      </LazyLoad>
      <LazyLoad height={460} offset={80}>
        <ImageTextBlock
          reverse
          title="What we've been up to"
          cta="Read our latest news"
          link="/latest-news"
          image={WhatWeveBeenUpTo}
          altTag="Latest news"
        >
          We love the property market &mdash; and we&apos;re always looking for
          new ways to make your life easier. Here&apos;s what&apos;s new with
          Strike:
        </ImageTextBlock>
      </LazyLoad>
      <LazyLoad height={460} offset={80}>
        <ImageTextBlock
          title="Have questions?"
          cta="Contact us"
          link="/contact-us"
          image={HaveQuestions}
          altTag="Contact us"
        >
          We&apos;ve got answers. And we&apos;re always happy to hear from you.
        </ImageTextBlock>
      </LazyLoad>
    </Layout>
  );
};

export default WhyStrike;

import React from 'react';
import {
  ShowBreakpoint,
  HideBreakpoint,
  Underline,
  Text,
} from '@strikelabs/luna';

import { Outer, IntroLarge, ColumnWrapper, Value, ValueTitle } from './style';

const OurValues = () => {
  return (
    <>
      <Outer>
        <Text as="h2" ext mb={3}>
          Our Values
        </Text>
        <HideBreakpoint breakpoint="desktop">
          <Text as="p" ext mb={4}>
            We keep our values at the heart of what we do and our culture has
            grown into something pretty special &mdash; for our customers and
            for us.
          </Text>
        </HideBreakpoint>
        <ShowBreakpoint breakpoint="desktop">
          <IntroLarge>
            We keep our values at the heart of what we do and our culture has
            grown into something pretty special &mdash; for our customers and
            for us.
          </IntroLarge>
        </ShowBreakpoint>
        <ColumnWrapper>
          <Value>
            <ValueTitle>
              <Underline weight="h2">Transparent</Underline>
            </ValueTitle>
            <Text styleType="body" ext>
              We&apos;re honest, straightforward, and open. Most importantly,
              we&apos;re on your side.
            </Text>
          </Value>
          <Value>
            <ValueTitle>
              <Underline weight="h2">Empowering</Underline>
            </ValueTitle>
            <Text styleType="body" ext>
              You&apos;re in control &mdash; and we&apos;re always here,
              whenever you need us.
            </Text>
          </Value>
          <Value>
            <ValueTitle>
              <Underline weight="h2">Collaborative</Underline>
            </ValueTitle>
            <Text styleType="body" ext>
              We&apos;re all about people &mdash; and we know that building
              strong partnerships creates the best results for everyone.
            </Text>
          </Value>
          <Value>
            <ValueTitle>
              <Underline weight="h2">Digital</Underline>
            </ValueTitle>
            <Text styleType="body" ext>
              We use data insights and technology to make your journey easier
              and create a seamless move, from start to finish.
            </Text>
          </Value>
        </ColumnWrapper>
      </Outer>
    </>
  );
};

export default OurValues;

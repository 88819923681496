import styled from 'styled-components';
import { Flex, Text } from '@strikelabs/luna';
import { rem } from 'polished';

import { Container } from 'components/common';

export const Outer = styled(Container)`
  margin-bottom: ${({ theme }) => theme.spacing.lg};

  ${({ theme }) => theme.media.desktop} {
    margin-bottom: ${({ theme }) => theme.spacing.xl};
  }
`;

export const ColumnWrapper = styled(Flex)`
  flex-direction: column-reverse;

  ${({ theme }) => theme.media.desktop} {
    flex-direction: ${({ reverse }) => (reverse ? 'row-reverse' : 'row')};
  }
`;

export const TextColumn = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  width: 100%;

  ${({ theme }) => theme.media.desktop} {
    width: 50%;
    margin-bottom: 0;
    padding-left: ${({ theme }) => theme.spacing.lg};
    padding-right: ${({ theme, reverse }) =>
      reverse ? 0 : theme.defaults.gutterSpacing};
  }

  ${({ theme }) => theme.media.giant} {
    padding-left: ${({ theme }) => theme.spacing.xl};
  }
`;

export const ImageColumn = styled(Flex)`
  width: ${({ theme }) => `calc(100% + ${theme.defaults.gutterSpacing * 2})`};
  height: ${rem(263)};
  margin: ${({ theme }) =>
    `0 -${theme.defaults.gutterSpacing} ${theme.spacing.lg}`};

  ${({ theme }) => theme.media.smallTablet} {
    height: ${rem(322)};
    width: 100%;
    margin: ${({ theme }) => `0 0 ${theme.spacing.lg}`};
  }

  ${({ theme }) => theme.media.desktop} {
    width: 50%;
    height: ${rem(460)};
    margin: ${({ theme, reverse }) =>
      reverse
        ? `0 ${theme.defaults.halfGutterSpacing} 0 0`
        : `0 0 0 ${theme.defaults.halfGutterSpacing}`};
  }
`;

export const TitleLarge = styled(Text).attrs({
  as: 'h2',
  ext: true,
})`
  font-size: ${rem(40)};
  line-height: ${rem(50)};
  letter-spacing: -0.2px;
  font-weight: 500;
  margin-bottom: ${({ theme }) => theme.spacing.md};
`;

export const Image = styled.div`
  width: 100%;
  height: 100%;
  background-image: ${({ image }) => `url(${image})`};
  background-position: center;
  background-size: cover;
`;

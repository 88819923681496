import React from 'react';
import PropTypes from 'prop-types';
import {
  ButtonAnimated,
  HideBreakpoint,
  ShowBreakpoint,
  Text,
  Box,
} from '@strikelabs/luna';

import {
  ColumnWrapper,
  Outer,
  TextColumn,
  ImageColumn,
  TitleLarge,
  Image,
} from './style';

const ImageTextBlock = ({
  reverse,
  title,
  children,
  cta,
  link,
  image,
  altTag,
}) => {
  const hasCTA = cta && link;
  return (
    <Outer>
      <ColumnWrapper reverse={reverse}>
        <TextColumn reverse={reverse}>
          {title && (
            <>
              <HideBreakpoint breakpoint="desktop">
                <Text as="h2" ext mb={4}>
                  {title}
                </Text>
              </HideBreakpoint>
              <ShowBreakpoint breakpoint="desktop">
                <TitleLarge>{title}</TitleLarge>
              </ShowBreakpoint>
            </>
          )}
          <HideBreakpoint breakpoint="desktop">
            <Text styleType="body" ext mb={4}>
              {children}
            </Text>
          </HideBreakpoint>
          <ShowBreakpoint breakpoint="desktop">
            <Box width={[1, 1, 1, 1, 1, 8.5 / 10]}>
              <Text as="p" ext mb={3}>
                {children}
              </Text>
            </Box>
          </ShowBreakpoint>
          {hasCTA && (
            <Box>
              <ButtonAnimated to={link}>{cta}</ButtonAnimated>
            </Box>
          )}
        </TextColumn>
        <ImageColumn>
          <Image image={image} altTag={altTag} />
        </ImageColumn>
      </ColumnWrapper>
    </Outer>
  );
};

ImageTextBlock.propTypes = {
  reverse: PropTypes.bool,
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  cta: PropTypes.string,
  link: PropTypes.string,
  image: PropTypes.string.isRequired,
  altTag: PropTypes.string.isRequired,
};

ImageTextBlock.defaultProps = {
  reverse: false,
  title: null,
  cta: null,
  link: null,
};

export default ImageTextBlock;
